/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./style.scss";
import { withRouter, useParams, Link } from "react-router-dom";
import ImageLogo from "app/assets/boom-logo-v2-blue.png";
import IconArrowDown from "app/assets/icon-arrow-down.png";
import IconBack from "app/assets/icon-back-grey.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import EventAPI from "app/apis/event";
import ChannelSessionAPI from "app/apis/channelSession";
import IconLive from "app/assets/icon-session-live@2x.png";
import IconCalendar from "app/assets/icon-calendar.png";
import LocalStorageService from "app/services/localStorageService";
import { useDispatch, useSelector } from "react-redux";
import { eventActions } from "app/states/event";
import IconWarningTriangle from "app/assets/icon-alert-triangle-yellow.png";
import UsageAPI from "app/apis/usage";
import IconChannel from "app/assets/icon-menu-event-channels.png";

const SessionSidebar = ({ history }) => {
	const { eventId, channelId, sessionId } = useParams();

	const dispatch = useDispatch();
	const setSelectedChannelForSessionSidebar = (channel) =>
		dispatch(eventActions.setSelectedChannelForSessionSidebar(channel));
	const setSelectedDateForSessionSidebar = (selectedDate) =>
		dispatch(eventActions.setSelectedDateForSessionSidebar(selectedDate));
	const openSessionControlCreateChannelModal = () => dispatch(eventActions.openSessionControlCreateChannelModal());

	const selectedDate = useSelector(({ event }) => event.selectedDateForSessionSidebar);
	const selectedOrganizationAccount = useSelector(({ organization }) => organization.selectedOrganizationAccount);
	const eventDetails = useSelector(({ event }) => event.eventDetails);
	const refreshSessionControlListingCount = useSelector(({ event }) => event.refreshSessionControlListingCount);

	const [showDatePicker, setShowDatePicker] = useState(false);

	const [channelListing, setChannelListing] = useState([]);
	const [selectedChannel, setSelectedChannel] = useState(null);

	const [sessionListing, setSessionListing] = useState([]);
	const [totalSessionPageCount, setTotalSessionPageCount] = useState(1);

	const [sessionUsage, setSessionUsage] = useState(null);

	// Set the selected date to first day of event if event has over
	useEffect(() => {
		if (eventDetails) {
			const eventEndDate = moment(eventDetails.event_end_date, "DD MMMM");

			if (moment().diff(eventEndDate, "days") > 0) {
				const eventStartDate = moment(eventDetails.event_start_date, "DD MMMM").toDate();
				setSelectedDateForSessionSidebar(eventStartDate);
			}
		}
	}, [eventDetails]);

	// Get channel details
	useEffect(() => {
		if (!channelId || channelId === "null") return;

		EventAPI.getEventChannelDetails(channelId)
			.then((response) => {
				const { name, thumbnail_url } = response.data;

				setSelectedChannel({
					id: channelId,
					name: name,
					thumbnail: thumbnail_url,
				});

				setSelectedChannelForSessionSidebar({
					id: channelId,
					name: name,
					thumbnail: thumbnail_url,
				});
			})
			.catch((error) => {
				console.error(error);
			});
	}, [channelId]);

	// Get channel listing
	useEffect(() => {
		let params = {
			limit: 750,
			event_id: eventId,
			sort_by: "name",
			sort_order: "asc",
		};

		EventAPI.getEventChannelListing(params)
			.then((response) => {
				const { event_channels } = response.data;
				setChannelListing(event_channels);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	// Get session listing
	useEffect(() => {
		if (!selectedChannel) return;

		let params = {
			channel_id: selectedChannel ? selectedChannel.id : channelId,
			// page: page,
			limit: 750,
		};

		if (selectedDate) {
			params["search_string"] = moment(selectedDate).format("YYYY-MM-DD");
		}

		ChannelSessionAPI.getChannelSessionListing(params)
			.then((response) => {
				const { event_channel_sessions, paging } = response.data;
				const { last_page } = paging;

				setSessionListing(event_channel_sessions);
				setTotalSessionPageCount(last_page);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [selectedChannel, selectedDate, refreshSessionControlListingCount]);

	// Get session usage limit
	useEffect(() => {
		UsageAPI.getSessionUsage(selectedOrganizationAccount.id)
			.then((response) => {
				setSessionUsage(response);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	const handleSelectChannel = (selectedChannel) => {
		const { id, name, thumbnail_url } = selectedChannel;

		setSelectedChannelForSessionSidebar(selectedChannel);
		setSelectedChannel({
			id: id,
			name: name,
			thumbnail: thumbnail_url,
		});
	};

	const handleAddNewSession = () => {
		if (!selectedChannel) {
			openSessionControlCreateChannelModal();
			return;
		}

		// If session usage not available
		if (sessionUsage && sessionUsage.available <= 0 && sessionUsage.limit !== -1) {
			return;
		}

		let selectedChannelId = selectedChannel.id;
		history.replace(`/events/view/${eventId}/channelSessionControlRoom/channels/${selectedChannelId}/session/add`);
	};

	const handleViewSessionRoom = (sessionRoomId) => {
		let selectedChannelId = selectedChannel.id;
		history.replace(`/events/view/${eventId}/channels/${selectedChannelId}/session/${sessionRoomId}`);
	};

	const handleSelectDate = (date) => {
		setSelectedDateForSessionSidebar(date);
		setShowDatePicker(false);
	};

	const handleChannelDropdownOnClick = () => {
		if (!selectedChannel) {
			openSessionControlCreateChannelModal();
		}
	};

	return (
		<div className="session-sidebar">
			<section className="logo-wrapper px-4 py-3 mb-3">
				<img src={ImageLogo} alt="logo" className="img-fluid img-logo" />
			</section>

			<section className="page-header d-flex justify-content-between align-items-center px-4 mb-4">
				<div className="d-flex align-items-center">
					<div className="btn-back" onClick={() => history.goBack()}>
						<img src={IconBack} alt="back" className="img-fluid" />
					</div>
					<div>
						<h2 className="title">Control Center</h2>
						<span className="subtitle">Channel Sessions Management</span>
					</div>
				</div>
			</section>

			<section className="px-4 mb-4">
				<div className="dropdown dropdown-selection">
					<button
						className="btn-dropdown-toggle w-100"
						type="button"
						id="calendar_dropdown"
						onClick={() => setShowDatePicker(!showDatePicker)}>
						<div className="row align-items-center mx-0 text-left px-3 py-2">
							<div className="col-lg-2 px-0 text-center">
								<img src={IconCalendar} alt="calendar" className="icon-calendar" />
							</div>
							<div className="col-lg-9">
								<h6
									className="dropdown-name mb-0"
									title={`${moment(selectedDate).isSame(new Date(), "day") && "Today, "} ${moment(
										selectedDate
									).format("Do MMMM YYYY")}`}>
									{moment(selectedDate).isSame(new Date(), "day") && "Today, "}
									{moment(selectedDate).format("Do MMMM YYYY")}
								</h6>
							</div>
							<div className="col-1 pl-0">
								<img src={IconArrowDown} alt="down" className="icon-arrow-down" />
							</div>
						</div>
					</button>
					{showDatePicker && (
						<>
							<div
								className="custom-selection-wrapper-bgOverlay"
								onClick={() => setShowDatePicker(false)}></div>
							<div className="calendar-dropdown">
								<DatePicker
									selected={selectedDate}
									onChange={handleSelectDate}
									dateFormat="dd MMMM yyyy"
									inline
									minDate={moment(eventDetails.event_start_date, "DD MMMM").toDate()}
									maxDate={moment(eventDetails.event_end_date, "DD MMMM").toDate()}
								/>
							</div>
						</>
					)}
				</div>
			</section>

			<section className="px-4 mb-5">
				{selectedChannel ? (
					<div className="dropdown dropdown-selection">
						<button
							className="btn-dropdown-toggle dropdown-toggle w-100"
							type="button"
							id="channel_dropdown"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false">
							<div
								className="row align-items-center mx-0 text-left px-3 py-2"
								date-id={selectedChannel.id}>
								<div className="col-lg-2 px-0 text-center">
									<img src={selectedChannel.thumbnail} alt="logo" className="logo-thumbnail" />
								</div>
								<div className="col-lg-9 pl-3">
									<h6 className="dropdown-name" title={selectedChannel.name}>
										{selectedChannel.name}
									</h6>
									<div className="dropdown-desc">Channel Room</div>
								</div>
								<div className="col-1 pl-0">
									<img src={IconArrowDown} alt="down" className="icon-arrow-down" />
								</div>
							</div>
						</button>
						<div
							className="dropdown-menu w-100 dropdown-menu-container py-0"
							aria-labelledby="channel_dropdown">
							{channelListing.map((channel) => {
								const { id, name, thumbnail_url } = channel;

								return (
									<div
										key={`channel-${id}`}
										className="dropdown-menu-item row align-items-center mx-0 p-3"
										onClick={() => handleSelectChannel(channel)}>
										<div className="col-lg-2 px-0 text-center">
											<img src={thumbnail_url} alt="logo" className="logo-thumbnail" />
										</div>
										<div className="col-lg-9 pl-3">
											<h6 className="dropdown-name" title={name}>
												{name}
											</h6>
											<div className="dropdown-desc">Channel Room</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				) : (
					<button className="btn-add-channel w-100">
						<div
							className="row align-items-center mx-0 text-left px-3 py-2"
							onClick={handleChannelDropdownOnClick}>
							<div className="col-lg-2 px-0 text-center">
								<img src={IconChannel} alt="logo" className="icon-channel" />
							</div>
							<div className="col-lg-9 pl-3">
								<h6 className="btn-name" title="Add a Room">
									Add a Room
								</h6>
								<div className="btn-desc">Channel Room</div>
							</div>
							<div className="col-1 pl-0">
								<img src={IconArrowDown} alt="down" className="icon-arrow-down" />
							</div>
						</div>
					</button>
				)}
			</section>

			<section className="px-4 mb-4 session-listing-section">
				{sessionListing.map((session, index) => {
					const { id, name, thumbnail_url, live, start_at, end_at } = session;

					const startDate = moment(start_at).format("ddd, Do MMMM");
					const startTime = moment(start_at).format("h:mma");
					const endTime = moment(end_at).format("h:mma");
					const sessionDateTime = `${startTime} - ${endTime}, ${startDate}`;

					const isLast = sessionListing.length - 1 === index;

					return (
						<div
							key={`session-${id}`}
							className={`d-flex align-items-start session-list-item`}
							onClick={() => handleViewSessionRoom(id)}>
							<img src={thumbnail_url} alt="session thumbnail" className={`session-thumbnail`} />
							<div className={`session-content-wrapper connector ${isLast && "connector-gray"}`}>
								<div className={`session-title ${parseInt(sessionId) === id ? "current-viewing" : ""}`}>
									{name}
								</div>
								<div
									className={`session-datetime ${
										parseInt(sessionId) === id ? "current-viewing" : ""
									}`}>
									{sessionDateTime}
								</div>
								{live === 1 && (
									<div className="mb-1">
										<img src={IconLive} alt="live" className="icon-live" />
									</div>
								)}
							</div>
						</div>
					);
				})}

				<div
					className={`d-flex align-items-center session-list-item btn-add-session
						${sessionUsage && sessionUsage.available <= 0 && sessionUsage.limit !== -1 ? "disabled" : ""}`}
					onClick={handleAddNewSession}>
					<div className={`session-thumbnail session-thumbnail-gray icon-add`}>+</div>
					<div className={`session-content-wrapper`}>
						<div className="text-add-new-session">Add New Session</div>
						{sessionUsage && sessionUsage.available <= 0 && sessionUsage.limit !== -1 && (
							<div className="warning-container">
								<img src={IconWarningTriangle} alt="warning" className="icon-warning" />
								<div>
									Sessions limit reached! Upgrade your plan now to create more sessions.{" "}
									<div>
										<Link
											to="/billings"
											className="text-manage-subscription"
											onClick={(e) => e.stopPropagation()}>
											Manage Subscription
										</Link>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</section>
		</div>
	);
};

export default withRouter(SessionSidebar);
