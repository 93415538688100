/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useParams, Link } from "react-router-dom";
import "./style.scss";
import EventLayoutContainer from "app/views/containers/EventLayoutContainer/EventLayoutContainer";
import AppPagination from "app/views/components/AppPagination/AppPagination";
import EventAPI from "app/apis/event";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import IconWarningTriangle from "app/assets/icon-alert-triangle-yellow.png";
import EventInfo from "app/views/components/EventInfo/EventInfo";
import UsageAPI from "app/apis/usage";
import { useSelector } from "react-redux";

const MAX_TABLE_ROWS = 20;

const EventVisitors = ({ history }) => {
	const { eventId } = useParams();

	const selectedOrganizationAccount = useSelector(({ organization }) => organization.selectedOrganizationAccount);
	
	const [searchText, setSearchText] = useState("");
	const [page, setPage] = useState(1);
	const [totalPageCount, setTotalPageCount] = useState(0);
	const [eventVisitors, setEventVisitors] = useState([]);

	const [sortBy, setSortBy] = useState("");
	const [sortOrdering, setSortOrdering] = useState({
		first_name: "asc",
		last_name: "asc",
		email: "asc",
		company_name: "asc",
	});

	const [visitorUsage, setVisitorUsage] = useState(null);

	// Get event visitor listing
	useEffect(() => {
		if (!eventId) return;

		let params = {
			page: page,
			limit: MAX_TABLE_ROWS,
			event_id: eventId,
		};

		if (searchText) {
			params["search_string"] = searchText;
		}

		if (sortBy) {
			params["sort_by"] = sortBy;
			params["sort_order"] = sortOrdering[sortBy];
		}

		EventAPI.getEventVisitors(params)
			.then((response) => {
				const { event_visitors, paging } = response.data;

				// Set event visitors
				setEventVisitors(event_visitors);

				// Set paging
				const { last_page } = paging;
				setTotalPageCount(last_page);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [page, searchText, eventId]);

	// Get visitor usage limit
	useEffect(() => {
		UsageAPI.getVisitorUsage(selectedOrganizationAccount.id)
			.then((response) => {
				setVisitorUsage(response);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	const handlePageChange = (selectedPage) => {
		setPage(selectedPage);
	};

	const handleSearchChange = (event) => {
		setSearchText(event.target.value);
		setPage(1);
	};

	const handleSorting = (sortByColumn) => {
		let sortOrder = "asc";

		sortOrder = sortOrdering[sortByColumn] === "asc" ? "desc" : "asc";

		setSortOrdering({
			...sortOrdering,
			[sortByColumn]: sortOrder,
		});

		setSortBy(sortByColumn);

		let params = {
			event_id: eventId,
			page: 1,
			limit: MAX_TABLE_ROWS,
			sort_by: sortByColumn,
			sort_order: sortOrder,
		};

		if (searchText) {
			params["search_string"] = searchText;
		}

		EventAPI.getEventVisitors(params)
			.then((response) => {
				const { event_visitors, paging } = response.data;

				// Set event visitors
				setEventVisitors(event_visitors);

				// Set paging
				const { last_page } = paging;
				setTotalPageCount(last_page);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<EventLayoutContainer>
			<div id="event-visitor-page">
				<section className="page-header d-flex justify-content-between align-items-center">
					<div className="d-flex align-items-center">
						<div>
							<h2 className="title">Event Visitor Listing</h2>
							<span className="subtitle">Event Details &gt; Visitors</span>
						</div>
					</div>
					<EventInfo />
				</section>

				{visitorUsage && visitorUsage.available <= 0 && visitorUsage.limit !== -1 && (
					<section className="entitlement-warning-container mt-4 mb-3 px-5">
						<img src={IconWarningTriangle} alt="warning" className="icon-entitlement-warning" />
						<div className="text-entitlement-warning">
							Visitors limit reached! Upgrade your plan now to increase visitor attendance.
						</div>
						<Link className="text-manage-subscription" to="/billings">Manage Subscription</Link>
					</section>
				)}

				<section className="px-5 py-4">
					<div className="row">
						<div className="col-12">
							<div className="form-group mb-0">
								<input
									type="text"
									className="form-control"
									name="search"
									placeholder="Search"
									value={searchText}
									onChange={handleSearchChange}
								/>
							</div>
						</div>
					</div>
				</section>

				<section>
					<table className="table">
						<thead>
							<tr>
								<th
									scope="col"
									className="table-header cursor-pointer"
									onClick={() => handleSorting("first_name")}>
									First Name{" "}
									<FontAwesomeIcon
										icon={sortOrdering["first_name"] === "desc" ? faChevronDown : faChevronUp}
									/>
								</th>
								<th
									scope="col"
									className="table-header cursor-pointer"
									onClick={() => handleSorting("last_name")}>
									Last Name{" "}
									<FontAwesomeIcon
										icon={sortOrdering["last_name"] === "desc" ? faChevronDown : faChevronUp}
									/>
								</th>
								<th
									scope="col"
									className="table-header cursor-pointer"
									onClick={() => handleSorting("email")}>
									Email Address{" "}
									<FontAwesomeIcon
										icon={sortOrdering["email"] === "desc" ? faChevronDown : faChevronUp}
									/>
								</th>
								<th scope="col" className="table-header">
									Phone Number
								</th>
								<th
									scope="col"
									className="table-header cursor-pointer"
									onClick={() => handleSorting("company_name")}>
									Company Name{" "}
									<FontAwesomeIcon
										icon={sortOrdering["company_name"] === "desc" ? faChevronDown : faChevronUp}
									/>
								</th>
							</tr>
						</thead>
						<tbody>
							{eventVisitors.map((visitor, index) => {
								const { id, first_name, last_name, email, phone_no, company_name } = visitor;

								return (
									<tr key={`visitor-${id}-${index}`}>
										<td className="table-cell">{first_name || "-"}</td>
										<td className="table-cell">{last_name || "-"}</td>
										<td className="table-cell">{email || "-"}</td>
										<td className="table-cell">{phone_no || "-"}</td>
										<td className="table-cell">{company_name || "-"}</td>
									</tr>
								);
							})}
						</tbody>
					</table>

					<AppPagination pageCount={totalPageCount} handlePageChange={handlePageChange} />
				</section>
			</div>
		</EventLayoutContainer>
	);
};

export default withRouter(EventVisitors);
