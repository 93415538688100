import axios from "axios";

axios.defaults.baseURL = '//api.dev.boom.rocks/admin'

//PROD API //api.boom.rocks/admin/
//DEV API  //api.dev.boom.rocks/admin

const ApiAction = {
    get(url, params = {}, headers = {}) {
        return axios.get(url, { params, headers });
    },

    post(url, body, config = {}) {
        return axios.post(url, body, config);
    },

    delete(url, params = {}, data = {}) {
        return axios.delete(url, { params, data });
    },

    put(url, body) {
        return axios.put(url, body);
    },

    patch(url, body) {
        return axios.patch(url, body);
    }

};

export default ApiAction;
