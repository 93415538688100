/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import "./style.scss";
import AppPagination from "app/views/components/AppPagination/AppPagination";
import VisitorAPI from "app/apis/visitor";
import LayoutContainer from "app/views/containers/LayoutContainer/LayoutContainer";
import SessionStorageService from "app/services/sessionStorageService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import IconWarningTriangle from "app/assets/icon-alert-triangle-yellow.png";
import UsageAPI from "app/apis/usage";

const MAX_TABLE_ROWS = 10;

const VisitorList = ({ history }) => {
	const lastViewingPage = SessionStorageService.getLastViewingPage();

	const selectedOrganizationAccount = useSelector(({ organization }) => organization.selectedOrganizationAccount);

	const [searchText, setSearchText] = useState("");
	const [page, setPage] = useState(lastViewingPage || 1);
	const [totalPageCount, setTotalPageCount] = useState(0);
	const [visitors, setVisitors] = useState([]);
	const [loadedVisitorListing, setLoadedVisitorListing] = useState(false);

	const [sortBy, setSortBy] = useState("");
	const [sortOrdering, setSortOrdering] = useState({
		first_name: "asc",
		last_name: "asc",
		email: "asc",
		company_name: "asc",
	});

	const [visitorUsage, setVisitorUsage] = useState(null);

	// Get visitor listing
	useEffect(() => {
		if (!selectedOrganizationAccount) return;

		let params = {
			page: page,
			limit: MAX_TABLE_ROWS,
			organization_id: selectedOrganizationAccount.id,
		};

		if (searchText) {
			params["search_string"] = searchText;
		}

		if (sortBy) {
			params["sort_by"] = sortBy;
			params["sort_order"] = sortOrdering[sortBy];
		}

		VisitorAPI.getVisitorListing(params)
			.then((response) => {
				const { visitors, paging } = response.data;

				// Set event visitors
				setVisitors(visitors);

				// Set paging
				const { last_page } = paging;
				setTotalPageCount(last_page);

				setLoadedVisitorListing(true);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [page]);

	// Search visitor listing
	useEffect(() => {
		if (!loadedVisitorListing) return;

		const timeout = setTimeout(() => {
			let params = {
				page: page,
				limit: MAX_TABLE_ROWS,
				organization_id: selectedOrganizationAccount.id,
			};

			if (searchText) {
				params["search_string"] = searchText;
			}

			if (sortBy) {
				params["sort_by"] = sortBy;
				params["sort_order"] = sortOrdering[sortBy];
			}

			VisitorAPI.getVisitorListing(params)
				.then((response) => {
					const { visitors, paging } = response.data;

					// Set event visitors
					setVisitors(visitors);

					// Set paging
					const { last_page } = paging;
					setTotalPageCount(last_page);
				})
				.catch((error) => {
					console.log(error);
				});
		}, 500);

		return () => clearTimeout(timeout);
	}, [searchText]);

	// Get visitor usage limit
	useEffect(() => {
		if (!selectedOrganizationAccount) return;

		UsageAPI.getVisitorUsage(selectedOrganizationAccount.id)
			.then((response) => {
				setVisitorUsage(response);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [selectedOrganizationAccount]);

	const handlePageChange = (selectedPage) => {
		setPage(selectedPage);
	};

	const handleSearchChange = (event) => {
		setSearchText(event.target.value);
		setPage(1);
	};

	const handleViewDetails = (visitor_id) => {
		SessionStorageService.setLastViewingPage(page);
		history.push(`/visitors/view/${visitor_id}`);
	};

	const handleSorting = (sortByColumn) => {
		let sortOrder = "asc";

		sortOrder = sortOrdering[sortByColumn] === "asc" ? "desc" : "asc";

		setSortOrdering({
			...sortOrdering,
			[sortByColumn]: sortOrder,
		});

		setSortBy(sortByColumn);

		if (!selectedOrganizationAccount) return;

		let params = {
			page: 1,
			limit: MAX_TABLE_ROWS,
			sort_by: sortByColumn,
			sort_order: sortOrder,
			organization_id: selectedOrganizationAccount.id,
		};

		if (searchText) {
			params["search_string"] = searchText;
		}

		VisitorAPI.getVisitorListing(params)
			.then((response) => {
				const { visitors, paging } = response.data;

				// Set event visitors
				setVisitors(visitors);

				// Set paging
				const { last_page } = paging;
				setTotalPageCount(last_page);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<LayoutContainer>
			<div id="event-visitor-page">
				<section className="page-header d-flex justify-content-between align-items-center">
					<div className="d-flex align-items-center">
						<div>
							<h2 className="title">Visitor Database</h2>
							<span className="subtitle">Organization-wide visitor listing.</span>
						</div>
					</div>
				</section>

				{visitorUsage && visitorUsage.available <= 0 && visitorUsage.limit !== -1 && (
					<section className="entitlement-warning-container mt-4 mb-3">
						<img src={IconWarningTriangle} alt="warning" className="icon-entitlement-warning" />
						<div className="text-entitlement-warning">
							Visitors limit reached! Upgrade your plan now to increase visitor attendance.
						</div>
						<Link className="text-manage-subscription" to="/billings">Manage Subscription</Link>
					</section>
				)}

				<section className="pr-5 py-4">
					<div className="row">
						<div className="col-12">
							<div className="form-group mb-0">
								<input
									type="text"
									className="form-control"
									name="search"
									placeholder="Search"
									value={searchText}
									onChange={handleSearchChange}
								/>
							</div>
						</div>
					</div>
				</section>

				<section>
					<table className="table table-hover">
						<thead>
							<tr>
								<th
									scope="col"
									className="table-header cursor-pointer"
									onClick={() => handleSorting("first_name")}>
									First Name{" "}
									<FontAwesomeIcon
										icon={sortOrdering["first_name"] === "desc" ? faChevronDown : faChevronUp}
									/>
								</th>
								<th
									scope="col"
									className="table-header cursor-pointer"
									onClick={() => handleSorting("last_name")}>
									Last Name{" "}
									<FontAwesomeIcon
										icon={sortOrdering["last_name"] === "desc" ? faChevronDown : faChevronUp}
									/>
								</th>
								<th
									scope="col"
									className="table-header cursor-pointer"
									onClick={() => handleSorting("email")}>
									Email Address{" "}
									<FontAwesomeIcon
										icon={sortOrdering["email"] === "desc" ? faChevronDown : faChevronUp}
									/>
								</th>
								<th scope="col" className="table-header">
									Phone Number
								</th>
								<th
									scope="col"
									className="table-header cursor-pointer"
									onClick={() => handleSorting("company_name")}>
									Company Name{" "}
									<FontAwesomeIcon
										icon={sortOrdering["company_name"] === "desc" ? faChevronDown : faChevronUp}
									/>
								</th>
							</tr>
						</thead>
						<tbody>
							{visitors.map((visitor) => {
								const { visitor_id, first_name, last_name, email, phone_no, company_name } = visitor;

								return (
									<tr
										key={visitor_id}
										onClick={() => handleViewDetails(visitor_id)}
										className="cursor-pointer">
										<td className="table-cell">{first_name || "-"}</td>
										<td className="table-cell">{last_name || "-"}</td>
										<td className="table-cell">{email || "-"}</td>
										<td className="table-cell">{phone_no || "-"}</td>
										<td className="table-cell">{company_name || "-"}</td>
									</tr>
								);
							})}
						</tbody>
					</table>

					<AppPagination
						pageCount={totalPageCount}
						handlePageChange={handlePageChange}
						initialPage={page - 1}
					/>
				</section>
			</div>
		</LayoutContainer>
	);
};

export default withRouter(VisitorList);
